import Image from "next/image";
import logo from "../../../public/logo.png";

export default function Logo() {
	return (
		<Image
			src={logo}
			alt="Logo We Share Wine"
			priority
			width={160}
			height={48}
			layout="fixed"
		/>
	);
}
