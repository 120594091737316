import { useState } from "react";
import cx from "classnames";
import { Heading, Text, Bold } from "@/components/Typography";
import { Button } from "@/components/Button/Button";
import Input from "@/components/Form/Input/Input";
import { FlexColumn } from "@/components/layout/FlexBox";
import { FieldValues, useForm } from "react-hook-form";
import { NewsletterLayout } from "@/models/layouts";
import HtmlParser from "@/components/HtmlParser";

import s from "./Form.module.scss";

interface MauticForm extends FieldValues {
	captcha_email: string;
	emailadres: string;
	formId: string;
	formName: string;
	return: string;
	voornaam: string;
}

export function Form({
	illustration,
	title,
	text,
	under_text,
}: NewsletterLayout) {
	const [submissionState, setSubmissionstate] = useState<boolean>(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<MauticForm>();
	const onSubmit = async ({ mauticform }: MauticForm) => {
		// Semi solution thanks to:
		// https://forum.mautic.org/t/this-form-is-no-longer-available/17222/5

		/**
		 * Create form data from the form data object, lol
		 */
		let formData = new FormData();
		formData.append("mauticform[captcha_email]", mauticform.captcha_email);
		formData.append("mauticform[emailadres]", mauticform.emailadres);
		formData.append("mauticform[formId]", mauticform.formId);
		formData.append("mauticform[formName]", mauticform.formName);
		formData.append("mauticform[return]", mauticform.return);
		formData.append("mauticform[voornaam]", mauticform.voornaam);

		/**
		 * Build the request and send it
		 * I tried to follow the redirects that Mautic wants us to do, but no dice.
		 */
		const response = await fetch(
			"https://wesharewine.captuble.com/form/submit?formId=1",
			{
				mode: "no-cors",
				method: "POST",
				body: formData,
				redirect: "follow",
			}
		);
		// Responnse always seems to fail
		console.log("~ response", response);

		/**
		 * We need to implement our own response handling because this is some old-fashioned 90s PHP shit we're working with.
		 * We'll maintain a state that tells us if the form was submitted successfully or not.
		 * If it was, we'll show a custom success message that should be identical to the one from Mautic.
		 * We assume that we are good developers, so the submission won't go through if there are any errors, so we'll always set the state to true after getting a response.
		 */
		setSubmissionstate(true);
	};

	return (
		<div
			className={cx(s.form, { [s.formWithImage]: !!illustration })}
			style={
				illustration
					? { backgroundImage: `url(${illustration?.url || ""})` }
					: undefined
			}
		>
			<section className={s.section}>
				<FlexColumn gap={32} className={s.content}>
					<Heading as="h2" sizeMobile="lg" sizeDesktop="lg">
						{title}
					</Heading>
					<Text>
						<HtmlParser content={text} />
					</Text>
					{!submissionState ? (
						<form
							onSubmit={handleSubmit(onSubmit)}
							autoComplete="false"
							role="form"
							method="post"
							data-action="https://wesharewine.captuble.com/form/submit?formId=1"
							id="mauticform_emailnieuwsbrief"
							data-mautic-form="emailnieuwsbrief"
							encType="multipart/form-data"
						>
							<div
								className="mauticform-error"
								id="mauticform_emailnieuwsbrief_error"
							></div>
							<div
								className="mauticform-message"
								id="mauticform_emailnieuwsbrief_message"
							></div>
							<div className="mauticform-innerform">
								<div
									className="mauticform-page-wrapper mauticform-page-1"
									data-mautic-form-page="1"
								>
									<div
										id="mauticform_emailnieuwsbrief_voornaam"
										data-validate="voornaam"
										data-validation-type="text"
										className="mauticform-row mauticform-text mauticform-field-1 mauticform-required"
									>
										<Input
											label="Voornaam"
											id="mauticform_input_emailnieuwsbrief_voornaam"
											name="mauticform[voornaam]"
											placeholder="Voornaam"
											type="text"
											register={register}
											required
											errors={errors}
										/>
										<span
											className="mauticform-errormsg"
											style={{ display: "none" }}
										>
											Vergeet je voornaam niet in te vullen.
										</span>
									</div>

									<div
										id="mauticform_emailnieuwsbrief_emailadres"
										data-validate="emailadres"
										data-validation-type="email"
										className="mauticform-row mauticform-email mauticform-field-2 mauticform-required"
									>
										<Input
											id="mauticform_input_emailnieuwsbrief_emailadres"
											name="mauticform[emailadres]"
											placeholder="E-mailadres"
											type="email"
											register={register}
											required
											label="E-mailadres"
											errors={errors}
										/>
										<span
											className="mauticform-errormsg"
											style={{ display: "none" }}
										>
											Vergeet je e-mailadres niet in te vullen.
										</span>
									</div>

									<div
										id="mauticform_emailnieuwsbrief_captcha_email"
										className="mauticform-row mauticform-tel mauticform-field-3"
										style={{ display: "none" }}
									>
										<Input
											id="mauticform_input_emailnieuwsbrief_captcha_email"
											name="mauticform[captcha_email]"
											type="tel"
											register={register}
											errors={errors}
											label="tel"
										/>
										<span
											className="mauticform-errormsg"
											style={{ display: "none" }}
										></span>
									</div>

									<div
										id="mauticform_emailnieuwsbrief_submit"
										className="mauticform-row mauticform-button-wrapper mauticform-field-4"
									>
										<Button
											type="submit"
											name="mauticform[submit]"
											id="mauticform_input_emailnieuwsbrief_submit"
										>
											Inschrijven
										</Button>
									</div>
								</div>
							</div>

							<input
								type="hidden"
								id="mauticform_emailnieuwsbrief_id"
								{...register("mauticform[formId]", {
									value: "1",
								})}
							/>
							<input
								type="hidden"
								id="mauticform_emailnieuwsbrief_return"
								{...register("mauticform[return]", {
									value: "",
								})}
							/>
							<input
								type="hidden"
								id="mauticform_emailnieuwsbrief_name"
								{...register("mauticform[formName]", {
									value: "emailnieuwsbrief",
								})}
							></input>
						</form>
					) : (
						<div>
							<Text>
								<Bold>Bedankt voor je inschrijving.</Bold>
							</Text>
						</div>
					)}
					<Text size="small">
						<HtmlParser content={under_text}></HtmlParser>
					</Text>
				</FlexColumn>
			</section>
		</div>
	);
}
