import parse, {
	Element as ElementNode,
	domToReact,
	HTMLReactParserOptions,
} from "html-react-parser";
import { WP_Wysiwyg } from "@/models/wordpress";
import { Text, Bold, Italic, Ul, Ol, Li } from "@/components/Typography";
import Link from "next/link";

type Props = {
	content: WP_Wysiwyg;
	replaceOuterTag?: boolean;
};

export default function HtmlParser({ content, replaceOuterTag = true }: Props) {
	const handleElementNode = (node: ElementNode) => {
		if (node.parent === null && replaceOuterTag === true) {
			return <>{domToReact(node.children, PARSER_OPTIONS)}</>;
		}

		if (node.name === "italic" || node.name === "i") {
			return <Italic>{domToReact(node.children, PARSER_OPTIONS)}</Italic>;
		}

		if (node.name === "p") {
			return <Text>{domToReact(node.children, PARSER_OPTIONS)}</Text>;
		}

		if (node.name === "strong" || node.name === "b") {
			return <Bold>{domToReact(node.children, PARSER_OPTIONS)}</Bold>;
		}

		if (node.name === "ul") {
			return <Ul>{domToReact(node.children, PARSER_OPTIONS)}</Ul>;
		}

		if (node.name === "ol") {
			return <Ol>{domToReact(node.children, PARSER_OPTIONS)}</Ol>;
		}

		if (node.name === "li") {
			return <Li>{domToReact(node.children, PARSER_OPTIONS)}</Li>;
		}

		if (node.name === "a") {
			return (
				<Link href={node.attribs.href}>
					<a target={node.attribs.target} rel={node.attribs.rel}>
						{domToReact(node.children, PARSER_OPTIONS)}
					</a>
				</Link>
			);
		}

		return node;
	};

	const PARSER_OPTIONS: HTMLReactParserOptions = {
		replace: (domNode) => {
			switch (domNode.type) {
				case "tag":
					return handleElementNode(domNode as ElementNode);

				default:
					return domNode;
			}
		},
	};

	return <>{parse(content, PARSER_OPTIONS)}</>;
}
