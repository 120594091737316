import { FC, useState } from "react";
import { FocusOn } from "react-focus-on";
import Link from "next/link";
import cx from "classnames";
import { motion } from "framer-motion";
import { useNavigation } from "@/api/hooks";

import { LinkButton, IconButton } from "../Button";

import s from "./Menu.module.scss";

const container = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			when: "beforeChildren",
			staggerChildren: 0.15,
		},
	},
};

const item = {
	hidden: { opacity: 0, marginLeft: -50 },
	show: { opacity: 1, marginLeft: 0 },
};

const variants = {
	visible: { opacity: 1, transition: { delay: 0.75 } },
	hidden: { opacity: 0, transition: { delay: 0.5 } },
};

const Menu: FC = () => {
	const [navigation] = useNavigation();

	const [isOpen, setIsOpen] = useState(false);

	// useEffect(() => {
	// 	const body = document.getElementsByTagName("BODY")[0];

	// 	// add or remove the scrollbar if menu is open or closed
	// 	isOpen
	// 		? body.classList.add("no-scroll")
	// 		: body.classList.remove("no-scroll");
	// });

	const handleOnToggle = () => {
		setIsOpen(!isOpen);
	};

	const handleOnClose = () => {
		setIsOpen(false);
	};

	if (!navigation) {
		return null;
	}

	const {
		primary: primaryMenu,
		footer: secondaryMenu,
		icon: iconMenu,
	} = navigation;

	return (
		<FocusOn
			onClickOutside={handleOnClose}
			onEscapeKey={handleOnClose}
			enabled={isOpen}
		>
			<div className={s.menu}>
				<div className={s.menu__menu}>
					<IconButton
						icon={isOpen ? "close" : "menu"}
						label={isOpen ? "Sluiten" : "Menu"}
						showLabel
						onClick={handleOnToggle}
						className={s.menu__button}
					/>
				</div>
				<div className={cx(s.menu__open, { [s.opened]: isOpen })}>
					{isOpen && (
						<>
							<div className={s.menu__left}>
								<motion.ul
									variants={container}
									initial="hidden"
									animate="show"
									className={s.menu__links__primary}
								>
									{primaryMenu && (
										<>
											{primaryMenu.map((menu, index) => (
												<motion.li variants={item} key={`primary-${index}`}>
													<Link href={menu.url}>
														<a
															className={s.menu__link__primary}
															href={menu.url}
														>
															{menu.title}
														</a>
													</Link>
												</motion.li>
											))}
										</>
									)}
								</motion.ul>
								<motion.div
									initial="hidden"
									animate="visible"
									variants={variants}
									style={{ display: "flex" }}
								>
									<LinkButton
										href="/vragenlijst"
										rank="primary"
										icon="arrow-right"
									>
										Kies je wijn
									</LinkButton>
								</motion.div>
							</div>
							<motion.div
								className={s.menu__right}
								initial="hidden"
								animate="visible"
								variants={variants}
							>
								<div className={s.menu__icons}>
									{iconMenu && (
										<>{iconMenu.map((icon, index) => getSocial(icon, index))}</>
									)}
								</div>
								<ul className={s.menu__links__secondary}>
									{secondaryMenu && (
										<>
											{secondaryMenu.map((menu, index) => (
												<li
													key={`secondary-${index}`}
													className={s.menu__link__secondary}
												>
													<Link href={menu.url}>
														<a href={menu.url}>{menu.title}</a>
													</Link>
												</li>
											))}
										</>
									)}
								</ul>
								<span className={s.menu__copywrite}>
									WeShareWine © {new Date().getFullYear()}
								</span>
							</motion.div>
						</>
					)}
				</div>
			</div>
		</FocusOn>
	);
};

function getSocial(social: any, i: number) {
	switch (social.title) {
		case "whatsapp":
			return (
				<div key={`icon-${i}`} className={s.menu__social}>
					<IconButton
						as="a"
						href={social.url}
						icon="whatsapp"
						label="Deel via Whatsapp"
						target="_blank"
						rel="noreferrer noopener"
					/>
				</div>
			);
		case "facebook":
			return (
				<div key={`icon-${i}`} className={s.menu__social}>
					<IconButton
						key={`icon-${i}`}
						as="a"
						href={social.url}
						icon="facebook"
						label="Deel via Instagram"
						target="_blank"
					/>
				</div>
			);
		case "instagram":
			return (
				<div key={`icon-${i}`} className={s.menu__social}>
					<IconButton
						key={`icon-${i}`}
						as="a"
						href={social.url}
						icon="instagram"
						label="Deel via Instagram"
						target="_blank"
					/>
				</div>
			);
		case "mail":
			return (
				<div key={`icon-${i}`} className={s.menu__social}>
					<IconButton
						key={`icon-${i}`}
						as="a"
						href={social.url}
						icon="mail"
						label="Deel via E-mail"
					/>
				</div>
			);

		default:
			return null;
	}
}

export default Menu;
